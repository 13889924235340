import styled from "styled-components"
import colors from "../colors"

export const TopSection = styled.div`
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;

  h1 {
    margin-bottom: 30px;
    max-width: 500px;
    font-size: 36px;
    color: ${colors.VERDE_ESCURO};
    text-align: center;
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 25px;
      max-width: 330px;
    }
  }
`
